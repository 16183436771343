import dynamic from 'next/dynamic'
import { IMAGES } from 'shared/utils/contants'
import {
  ErrorContent,
  FooterGiftcardProps,
  HeaderGiftcardProps,
  ResponsiveContext
} from 'components'
import { Layout } from 'components/Layout'
import { useContentful } from '@smu-chile/pkg-unimarc-hooks'
import { Column } from '@smu-chile/pkg-unimarc-components'

const FooterGiftcard = dynamic<FooterGiftcardProps>(
  import('../components').then((mod) => {
    return mod.FooterGiftcard
  }),
  { ssr: true }
)

const HeaderGiftcard = dynamic<HeaderGiftcardProps>(
  import('../components').then((mod) => {
    return mod.HeaderGiftcard
  }),
  { ssr: true }
)

const NotFoundPage = ({
  isMobile
}: {
  isMobile: boolean
}): React.ReactElement => {
  const pastaAsset = useContentful({
    id_contentful: 'cake-image',
    options: {
      'sys.id': IMAGES.PASTA
    },
    type: 'assets'
  })
  const pastaImage = pastaAsset?.data?.['items']?.[0]?.fields?.file?.url
  return (
    <ResponsiveContext isMobile={isMobile}>
      <Column
        alignItems='center'
        customHeight={'100vh'}
        justifyContent='between'
      >
        <HeaderGiftcard
          alignItems='center'
          customWidth='100%'
          hideButtons
        />
        <ErrorContent
          asset={pastaImage}
          description='Tuvimos un problema para mostrarte esta página. Por favor inténtalo de nuevo y lo resolveremos'
          title='¡Qué enredo!'
        />
        <FooterGiftcard
          marginTop='0'
          paddingVertical='1rem'
          removeDescription
        />
      </Column>
    </ResponsiveContext>
  )
}

NotFoundPage.getLayout = (children) => {
  return <Layout backgroundColor='var(--color-base-white)'>{children}</Layout>
}

export default NotFoundPage
